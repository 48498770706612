import React,{useEffect} from "react";
import Aos from "aos";
import 'aos/dist/aos.css';


const Services = () => {
    useEffect (() => {
        Aos.init ();
      }, [])

    return (
        <div id='services' className='md:py-24 py-20 text-black justify-center mx-auto max-w-screen-xl text-center'>
            <div className='mx-auto justify-center px-3 md:px-5'>
                <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400">
                    <h1 className='text-base font-semibold text-[#CD252B]'>
                        Products and Services 
                    </h1>
                    <h1 className='md:text-4xl text-3xl justify-center pt-3 md:pt-5 font-semibold'>
                        Our Products and Services 
                    </h1>
                </div>
                <div className='lg:grid lg:grid-cols-4 md:grid md:grid-cols-2  mx-auto justify-center lg:gap-5 pt-10'>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md'>
                        <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                            <path d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
                            -1000z m1920 0 l0 -920 -920 0 -920 0 0 920 0 920 920 0 920 0 0 -920z"/>
                            <path d="M414 1565 c-46 -31 -54 -71 -54 -259 0 -176 0 -177 -23 -185 -29 -11
                            -75 -64 -82 -94 -4 -12 -5 -138 -3 -279 l3 -256 30 -43 c19 -27 50 -54 84 -71
                            l53 -28 352 0 c320 0 354 2 391 19 42 19 90 64 109 102 6 13 26 27 46 32 19 5
                            42 15 51 23 8 8 28 14 42 14 l27 0 0 -84 c0 -59 4 -87 14 -95 9 -8 66 -11 182
                            -9 l169 3 3 61 3 61 -39 16 c-111 44 -186 233 -198 498 -5 104 -10 137 -22
                            147 -20 15 -84 16 -100 0 -9 -9 -12 -70 -12 -215 0 -196 -1 -203 -20 -203 -19
                            0 -20 7 -20 184 0 249 20 230 -242 236 l-203 5 -6 115 c-11 221 -18 261 -49
                            292 l-28 28 -219 0 c-176 0 -223 -3 -239 -15z m456 -67 c7 -18 25 -212 29
                            -308 4 -79 19 -189 29 -207 15 -26 58 -41 75 -24 8 8 5 17 -12 34 -12 13 -24
                            41 -28 61 l-6 36 186 0 c177 0 187 -1 197 -20 7 -14 10 -100 8 -255 -3 -221
                            -4 -235 -23 -249 -19 -14 -20 -12 -27 35 -14 87 -54 141 -133 178 -39 18 -64
                            21 -185 21 -116 0 -142 -3 -146 -15 -4 -8 -1 -22 6 -30 10 -12 41 -15 152 -15
                            147 0 166 -5 206 -49 32 -36 42 -64 42 -121 0 -55 -19 -93 -64 -130 l-30 -25
                            -358 -3 c-402 -3 -408 -2 -453 67 -31 49 -39 91 -25 143 13 48 78 118 110 118
                            10 0 20 6 23 13 7 20 -4 37 -24 37 -23 0 -95 -35 -104 -50 -18 -29 -25 0 -25
                            103 0 156 23 212 96 239 l34 12 0 201 c0 143 3 204 12 213 9 9 72 12 220 12
                            207 0 209 0 218 -22z m675 -733 c39 -161 111 -284 185 -315 17 -7 30 -19 30
                            -26 0 -11 -26 -14 -129 -14 -108 0 -130 3 -135 16 -9 22 -8 628 1 651 4 10 14
                            -45 24 -132 9 -82 20 -163 24 -180z m-105 -140 c0 -28 -4 -35 -20 -35 -16 0
                            -20 7 -20 35 0 28 4 35 20 35 16 0 20 -7 20 -35z"/>
                            <path d="M482 1452 c-9 -7 -12 -48 -10 -173 l3 -164 190 0 190 0 -1 60 c-1
                            133 -16 260 -32 273 -18 14 -318 17 -340 4z m303 -54 c2 -7 6 -61 10 -120 l6
                            -108 -140 0 -141 0 0 113 c0 63 3 117 7 120 3 4 62 7 130 7 90 0 125 -3 128
                            -12z"/>
                            <path d="M1037 1014 c-12 -13 -7 -81 7 -93 21 -17 38 11 34 56 -3 36 -23 54
                            -41 37z"/>
                            <path d="M1157 1013 c-12 -12 -8 -91 6 -96 25 -9 47 13 47 46 0 44 -31 73 -53
                            50z"/>
                            <path d="M1277 1013 c-13 -12 -7 -80 7 -92 32 -27 64 57 34 87 -13 13 -31 16
                            -41 5z"/>
                            <path d="M494 785 c-9 -22 14 -45 45 -45 35 0 54 14 49 36 -4 26 -85 33 -94 9z"/>
                            <path d="M639 784 c-20 -25 14 -44 77 -44 59 0 86 19 64 45 -18 21 -123 21
                            -141 -1z"/>
                            <path d="M430 630 c-26 -26 -25 -54 2 -80 26 -24 37 -25 66 -4 42 29 17 104
                            -35 104 -7 0 -22 -9 -33 -20z"/>
                            <path d="M642 630 c-12 -12 -22 -29 -22 -40 0 -21 37 -60 57 -60 20 0 53 37
                            53 60 0 20 -36 60 -55 60 -6 0 -21 -9 -33 -20z"/>
                            <path d="M852 630 c-12 -12 -22 -29 -22 -40 0 -22 37 -60 57 -60 17 0 63 44
                            63 60 0 16 -46 60 -63 60 -7 0 -23 -9 -35 -20z"/>
                            <path d="M1068 633 c-34 -40 -30 -71 13 -93 50 -27 94 54 51 93 -24 22 -45 21
                            -64 0z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            Heavy Machinery
                        </h1>
                        <p className='md:text-base text-xs'>
                            No matter what heavy equipment you are looking for, our advisors are here to help every step of the way. We make it convenient and simple to locate the right construction equipment for you.
                        </p>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md mt-5 md:mt-0'>
                        <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                            <path d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
                            -1000z m1920 0 l0 -920 -925 0 -925 0 0 920 0 920 925 0 925 0 0 -920z"/>
                            <path d="M305 1561 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z"/>
                            <path d="M375 1560 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z"/>
                            <path d="M513 1554 c-7 -19 2 -28 22 -21 19 8 19 23 0 31 -9 3 -18 -1 -22 -10z"/>
                            <path d="M586 1563 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
                            <path d="M661 1557 c-8 -11 -11 -114 -9 -373 l3 -359 213 -3 213 -2 -3 -93 -3
                            -92 -158 -3 -157 -3 -20 45 c-31 74 -120 120 -183 96 -129 -51 -158 -177 -62
                            -270 32 -31 40 -34 99 -34 56 0 68 4 99 30 20 17 39 44 44 64 l9 35 207 3 208
                            2 18 -40 c10 -22 28 -49 41 -60 31 -27 92 -42 134 -35 45 9 102 58 116 100
                            l12 35 92 0 c58 0 96 4 104 12 14 14 16 104 2 113 -6 4 -10 58 -10 129 l0 123
                            -55 138 c-30 75 -55 143 -55 150 0 7 -16 27 -35 44 l-36 31 -184 0 -184 0 -3
                            113 -3 112 -221 3 c-179 2 -223 0 -233 -11z m417 -364 l2 -333 -200 0 -200 0
                            0 335 0 335 198 -2 197 -3 3 -332z m273 -21 l-2 -123 32 -29 c17 -17 42 -30
                            56 -30 14 0 36 -9 50 -20 19 -15 41 -20 84 -20 52 0 59 -2 59 -20 0 -16 -7
                            -20 -30 -20 -65 0 -94 -79 -44 -119 15 -12 38 -21 52 -21 22 0 24 -3 18 -30
                            -4 -22 -1 -32 9 -36 9 -4 15 -19 15 -40 l0 -34 -83 0 -84 0 -11 39 c-19 61
                            -113 120 -169 107 -52 -13 -104 -56 -124 -102 -14 -30 -26 -44 -39 -44 -19 0
                            -20 9 -20 335 l0 336 116 -3 116 -3 -1 -123z m142 116 c16 -13 87 -165 87
                            -188 0 -6 9 -28 20 -50 31 -61 27 -70 -26 -70 -37 0 -52 5 -70 25 -16 18 -34
                            25 -58 25 -25 0 -38 6 -46 19 -11 21 -14 234 -3 244 11 12 78 8 96 -5z m137
                            -448 c0 -25 -4 -30 -25 -30 -14 0 -28 7 -31 15 -10 25 4 45 31 45 21 0 25 -5
                            25 -30z m-987 -114 c89 -37 92 -167 6 -212 -81 -41 -169 15 -169 108 1 48 21
                            79 67 103 35 18 55 19 96 1z m765 -31 c91 -99 -28 -247 -144 -176 -39 24 -67
                            81 -58 118 26 103 132 134 202 58z"/>
                            <path d="M921 1226 l-64 -64 -38 25 c-76 50 -65 -3 12 -58 l27 -19 78 81 c87
                            88 94 99 67 99 -10 0 -47 -29 -82 -64z"/>
                            <path d="M1192 1244 c-20 -14 -22 -23 -22 -119 0 -96 2 -105 22 -119 12 -9 32
                            -16 43 -16 11 0 31 7 43 16 20 14 22 23 22 118 0 87 -3 105 -18 119 -23 21
                            -61 22 -90 1z m73 -119 l0 -90 -30 0 -30 0 -3 79 c-4 98 0 108 35 104 l28 -3
                            0 -90z"/>
                            <path d="M1485 730 c-11 -17 5 -32 21 -19 7 6 11 15 8 20 -7 12 -21 11 -29 -1z"/>
                            <path d="M1555 730 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
                            -12 6 -17 -2z"/>
                            <path d="M555 650 c-15 -16 -17 -26 -9 -48 8 -23 15 -27 49 -27 34 0 41 4 49
                            27 12 33 -14 68 -49 68 -12 0 -30 -9 -40 -20z m55 -30 c0 -5 -7 -10 -15 -10
                            -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>
                            <path d="M1285 650 c-15 -16 -17 -26 -9 -48 10 -29 31 -37 70 -28 19 5 24 13
                            24 39 0 18 -5 38 -12 45 -18 18 -53 14 -73 -8z m55 -30 c0 -5 -7 -10 -15 -10
                            -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>
                            <path d="M442 1548 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12 -12z"/>
                            <path d="M304 1489 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
                            -11z"/>
                            <path d="M304 1419 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
                            -11z"/>
                            <path d="M305 1341 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
                            -9z"/>
                            <path d="M305 1269 c-10 -15 1 -23 20 -15 9 3 13 10 10 16 -8 13 -22 13 -30
                            -1z"/>
                            <path d="M305 1201 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
                            -9z"/>
                            <path d="M307 1134 c-3 -3 -3 -12 0 -20 4 -11 9 -12 19 -3 8 6 11 15 8 20 -6
                            10 -18 12 -27 3z"/>
                            <path d="M300 1054 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z"/>
                            <path d="M307 994 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
                            12z"/>
                            <path d="M307 924 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
                            12z"/>
                            <path d="M304 849 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
                            -11z"/>
                            <path d="M375 850 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
                            <path d="M445 850 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
                            <path d="M510 840 c0 -23 25 -27 33 -6 7 16 -1 26 -20 26 -7 0 -13 -9 -13 -20z"/>
                            <path d="M577 854 c-9 -10 4 -34 19 -34 8 0 14 9 14 20 0 19 -19 27 -33 14z"/>
                            <path d="M372 768 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12 -12z"/>
                            <path d="M376 703 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
                            <path d="M370 621 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
                            -10 -4 -10 -9z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            Logistics Solutions
                        </h1>
                        <p className='md:text-base text-xs'>
                            We adhere to a standard process which helps us to provide you quality services which meet your expectation.
                        </p>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md mt-5 md:mt-0'>
                    <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                                <path d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
                                -1000z m1920 0 l0 -920 -920 0 -920 0 0 920 0 920 920 0 920 0 0 -920z"/>
                                <path d="M862 1438 c-7 -7 -12 -29 -12 -50 l0 -38 -45 0 c-52 0 -109 -21 -143
                                -52 -45 -44 -52 -72 -52 -227 0 -136 2 -149 25 -188 l24 -43 -40 0 -39 0 0
                                210 c0 197 -1 210 -19 220 -29 15 -243 13 -262 -2 -17 -14 -109 -135 -109
                                -143 0 -3 -20 -33 -45 -67 l-45 -62 0 -137 c0 -151 6 -169 59 -169 23 0 30 -7
                                48 -49 25 -61 69 -91 131 -91 24 0 57 8 74 18 30 17 68 77 68 107 0 13 38 15
                                319 15 l319 0 5 -30 c4 -16 22 -46 40 -67 31 -33 40 -37 96 -41 l62 -4 45 46
                                c28 29 44 55 44 71 0 38 13 30 38 -24 27 -60 72 -91 131 -91 71 0 134 48 142
                                110 3 17 13 26 35 32 47 12 58 31 51 86 -5 40 -2 50 13 60 11 7 29 30 40 52
                                17 34 20 58 20 180 0 118 -3 147 -19 178 -49 95 -75 102 -396 102 l-265 0 0
                                31 c0 67 -4 69 -173 69 -106 0 -157 -4 -165 -12z m288 -58 l0 -30 -130 0 -130
                                0 0 30 0 30 130 0 130 0 0 -30z m178 -247 c2 -138 5 -173 16 -173 8 0 16 9 19
                                20 5 18 14 20 86 20 74 0 81 -2 81 -20 0 -13 7 -20 20 -20 19 0 20 8 22 173
                                l3 172 65 3 c78 4 141 -18 170 -58 18 -25 20 -44 20 -177 0 -160 -8 -190 -62
                                -219 -30 -17 -991 -20 -1034 -4 -15 6 -39 22 -52 36 -23 23 -25 33 -30 149 -7
                                189 18 250 111 266 23 4 159 6 302 5 l260 -1 3 -172z m210 140 l3 -33 -80 0
                                c-45 0 -84 -1 -88 -2 -5 -2 -9 14 -11 35 l-3 38 88 -3 88 -3 3 -32z m-995 -43
                                c4 0 6 -111 5 -247 l-3 -248 -27 -3 c-16 -2 -31 4 -40 14 -7 9 -28 32 -46 50
                                -64 65 -166 51 -220 -31 -12 -19 -28 -35 -36 -35 -30 0 -37 27 -34 131 l3 104
                                150 5 150 5 3 86 c4 110 -4 119 -94 119 -35 0 -64 2 -64 5 0 3 7 16 14 28 15
                                22 38 24 239 17z m997 -61 l0 -29 -90 0 -90 0 0 30 0 29 78 3 c97 4 102 2 102
                                -33z m-1132 -97 c2 -45 -1 -64 -11 -67 -7 -3 -63 -4 -125 -3 l-111 3 47 68 46
                                68 76 -3 75 -3 3 -63z m1127 -2 l0 -35 -87 -3 -88 -3 0 41 0 41 88 -3 87 -3 0
                                -35z m-1128 -304 c23 -20 28 -32 28 -71 0 -40 -5 -51 -33 -76 -53 -48 -119
                                -33 -153 33 -17 34 -18 41 -5 72 28 68 109 89 163 42z m743 17 c0 -5 -8 -18
                                -18 -31 l-17 -22 -265 2 -265 3 -3 28 -3 27 285 0 c157 0 286 -3 286 -7z m184
                                -18 c21 -20 26 -33 26 -74 0 -45 -3 -53 -33 -75 -39 -30 -66 -32 -107 -11 -91
                                47 -57 185 45 185 31 0 49 -7 69 -25z m120 -5 c-9 -16 -21 -30 -29 -30 -11 0
                                -45 41 -45 55 0 3 20 5 44 5 l44 0 -14 -30z m197 -1 c48 -52 35 -126 -28 -158
                                -98 -50 -189 77 -114 158 24 25 37 31 71 31 34 0 47 -6 71 -31z m119 7 c0 -16
                                -7 -27 -21 -31 -11 -4 -22 -5 -23 -3 -1 1 -10 15 -19 31 l-17 27 40 0 c36 0
                                40 -3 40 -24z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            Chemical Transport
                        </h1>
                        <p className='md:text-base text-xs'>
                            Many in the chemical industry choose to outsource their shipment needs to chemical transportation companies in an effort to save time and money.
                        </p>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md mt-5 md:mt-0'>
                        <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                                <path d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
                                -1000z m1920 0 l0 -920 -920 0 -920 0 0 920 0 920 920 0 920 0 0 -920z"/>
                                <path d="M672 1679 c-23 -12 -52 -35 -65 -53 l-22 -31 -3 -585 c-2 -423 1
                                -592 9 -612 6 -16 27 -42 46 -58 l34 -30 316 0 316 0 39 40 38 41 0 608 0 607
                                -32 34 c-57 58 -70 60 -365 60 -252 0 -270 -1 -311 -21z m644 -62 c13 -15 24
                                -39 24 -53 l0 -24 -355 0 -355 0 0 23 c0 29 26 66 54 77 11 5 153 8 314 7
                                l294 -2 24 -28z m24 -582 l0 -455 -355 0 -355 0 0 455 0 455 355 0 355 0 0
                                -455z m0 -560 c0 -127 -20 -135 -353 -135 -139 0 -268 5 -288 10 -51 14 -69
                                46 -69 120 l0 60 355 0 355 0 0 -55z"/>
                                <path d="M955 1310 c-4 -6 -55 -47 -113 -91 -59 -44 -111 -87 -116 -96 -12
                                -23 13 -35 38 -17 16 11 64 14 221 14 157 0 205 -3 221 -14 13 -9 26 -11 34
                                -6 20 12 -3 36 -122 126 -53 41 -99 78 -103 84 -8 13 -52 13 -60 0z m100 -87
                                l58 -48 -67 -3 c-37 -2 -94 -2 -127 0 l-60 3 57 48 c31 26 62 47 69 47 7 0 39
                                -21 70 -47z"/>
                                <path d="M960 1220 c0 -26 25 -36 45 -20 12 10 13 16 4 26 -18 22 -49 17 -49
                                -6z m40 -11 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z"/>
                                <path d="M800 996 l0 -105 -27 -3 c-20 -2 -28 -9 -28 -23 0 -19 8 -20 229 -23
                                235 -2 271 3 245 34 -6 8 -20 14 -30 14 -17 0 -19 9 -19 105 0 98 -1 105 -20
                                105 -19 0 -20 -7 -20 -106 l0 -105 -32 3 -33 3 -3 103 c-2 94 -4 102 -22 102
                                -19 0 -20 -7 -20 -106 l0 -105 -36 3 -36 3 -2 100 c-1 91 -3 100 -20 100 -18
                                0 -19 -8 -20 -100 l-1 -100 -32 -3 -33 -3 0 105 c0 99 -1 106 -20 106 -19 0
                                -20 -7 -20 -104z"/>
                                <path d="M703 794 c-3 -9 0 -20 8 -25 18 -11 520 -11 538 0 8 5 11 16 8 25 -6
                                14 -38 16 -277 16 -239 0 -271 -2 -277 -16z"/>
                                <path d="M920 435 c0 -12 14 -15 65 -15 51 0 65 3 65 15 0 12 -14 15 -65 15
                                -51 0 -65 -3 -65 -15z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            Fintech Solutions
                        </h1>
                        <p className='md:text-base text-xs'>
                            FinTech is a major disruptor in the financial services industry, Business are facing sweeping technological changes...
                        </p>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md mt-5 md:mt-0'>
                        <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">
                                
                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                                <path d="M10 1000 l0 -1000 993 2 992 3 3 998 2 997 -995 0 -995 0 0 -1000z
                                m1908 2 l2 -922 -917 2 -918 3 -3 910 c-1 501 0 916 3 923 3 10 192 12 917 10
                                l913 -3 3 -923z"/>
                                <path d="M476 1534 c-13 -13 -16 -45 -16 -198 0 -164 2 -185 18 -199 16 -15
                                45 -17 194 -17 211 0 206 -3 210 115 l3 80 43 3 c33 3 42 7 42 22 0 15 -9 19
                                -42 22 l-43 3 -3 75 c-4 112 0 110 -213 110 -148 0 -180 -3 -193 -16z m359
                                -194 l0 -165 -164 -3 c-125 -2 -166 1 -173 10 -12 19 -10 286 2 309 10 18 21
                                19 173 17 l162 -3 0 -165z"/>
                                <path d="M532 1467 c-14 -16 -6 -27 20 -27 13 0 18 6 16 17 -4 22 -22 27 -36
                                10z"/>
                                <path d="M612 1467 c-14 -16 -6 -27 20 -27 13 0 18 6 16 17 -4 22 -22 27 -36
                                10z"/>
                                <path d="M690 1461 c0 -11 4 -22 9 -25 12 -8 34 21 26 34 -11 17 -35 11 -35
                                -9z"/>
                                <path d="M772 1467 c-14 -16 -6 -27 20 -27 13 0 18 6 16 17 -4 22 -22 27 -36
                                10z"/>
                                <path d="M1182 1530 c-21 -20 -22 -29 -22 -191 0 -167 0 -170 25 -194 21 -22
                                33 -25 100 -25 l75 0 0 -40 c0 -33 3 -40 20 -40 17 0 20 7 20 40 l0 40 73 0
                                c118 0 117 -1 117 217 0 162 -2 182 -18 196 -16 15 -45 17 -193 17 -163 0
                                -177 -1 -197 -20z m366 -192 l2 -168 -22 -1 c-76 -2 -312 1 -315 5 -7 6 -3
                                317 3 328 3 5 78 7 167 6 l162 -3 3 -167z"/>
                                <path d="M1487 1474 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
                                17z"/>
                                <path d="M1483 1384 c-8 -20 8 -36 26 -25 15 10 8 41 -9 41 -6 0 -13 -7 -17
                                -16z"/>
                                <path d="M1483 1304 c-9 -23 11 -38 26 -20 13 16 7 36 -10 36 -5 0 -12 -7 -16
                                -16z"/>
                                <path d="M1480 1220 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
                                -13 2 -18 -3 -18 -18z"/>
                                <path d="M1007 1346 c-7 -16 1 -26 20 -26 7 0 13 9 13 20 0 23 -25 27 -33 6z"/>
                                <path d="M1087 1353 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
                                13z"/>
                                <path d="M650 1060 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18 -13
                                2 -18 -3 -18 -18z"/>
                                <path d="M652 983 c4 -21 33 -25 33 -3 0 8 -8 16 -18 18 -14 3 -18 -1 -15 -15z"/>
                                <path d="M1360 980 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18 -13
                                2 -18 -3 -18 -18z"/>
                                <path d="M648 886 l-3 -41 -75 -3 c-113 -4 -110 1 -110 -217 0 -164 2 -184 18
                                -198 16 -15 45 -17 194 -17 157 0 177 2 191 18 15 16 17 45 17 199 0 218 3
                                213 -113 213 l-77 0 0 39 c0 30 -4 40 -19 44 -17 4 -20 -1 -23 -37z m187 -108
                                c3 -13 4 -90 3 -173 l-3 -150 -160 -3 c-142 -2 -161 -1 -172 15 -16 21 -18
                                285 -3 314 10 18 23 19 170 19 157 0 160 0 165 -22z"/>
                                <path d="M532 743 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17 -15
                                0 -20 -5 -18 -17z"/>
                                <path d="M532 677 c-14 -16 -6 -27 20 -27 13 0 18 6 16 17 -4 22 -22 27 -36
                                10z"/>
                                <path d="M531 596 c-9 -10 -8 -16 4 -26 19 -16 41 1 32 24 -8 20 -21 20 -36 2z"/>
                                <path d="M526 511 c-8 -12 21 -34 34 -26 17 11 11 35 -9 35 -11 0 -22 -4 -25
                                -9z"/>
                                <path d="M1360 899 c0 -25 33 -22 38 4 2 12 -3 17 -17 17 -15 0 -21 -6 -21
                                -21z"/>
                                <path d="M1184 819 c-14 -15 -20 -41 -24 -98 l-5 -76 -37 -3 c-30 -3 -38 -7
                                -38 -23 0 -16 7 -19 40 -19 l40 0 0 -73 c0 -64 3 -76 23 -95 22 -21 32 -22
                                199 -22 157 0 177 2 191 18 15 16 17 45 17 200 0 176 -1 181 -22 196 -18 13
                                -55 16 -194 16 -161 0 -172 -1 -190 -21z m361 -194 l0 -170 -165 0 -165 0 -3
                                160 c-1 87 0 165 3 172 3 11 40 13 167 11 l163 -3 0 -170z"/>
                                <path d="M1242 503 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
                                -15 0 -20 -5 -18 -17z"/>
                                <path d="M1316 511 c-8 -12 21 -34 34 -26 17 11 11 35 -9 35 -11 0 -22 -4 -25
                                -9z"/>
                                <path d="M1396 511 c-8 -12 21 -34 34 -26 17 11 11 35 -9 35 -11 0 -22 -4 -25
                                -9z"/>
                                <path d="M1482 503 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
                                -15 0 -20 -5 -18 -17z"/>
                                <path d="M926 632 c-2 -4 -1 -14 4 -22 7 -11 11 -11 24 -1 9 7 13 17 10 22 -7
                                11 -31 12 -38 1z"/>
                                <path d="M1000 633 c0 -17 19 -35 29 -29 18 12 12 36 -9 36 -11 0 -20 -3 -20
                                -7z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            Blockchain Technology
                        </h1>
                        <p className='md:text-base text-xs'>
                            Blockchain-based technology lets you cut out the middleman. With no third-party involvement, transacting isn’t only significantly cheaper for businesses, it’s also more secure and efficient.
                        </p>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md mt-5 md:mt-0'>
                        <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                                <path d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
                                -1000z m1928 2 l2 -922 -927 2 -928 3 -3 910 c-1 501 0 916 3 923 3 10 194 12
                                927 10 l923 -3 3 -923z"/>
                                <path d="M335 1585 l-25 -24 0 -554 c0 -514 1 -556 18 -576 l17 -21 674 0
                                c488 0 677 3 687 11 12 9 14 111 14 585 l0 573 -22 15 c-20 14 -101 16 -681
                                16 l-658 0 -24 -25z m1339 -57 c8 -30 8 -1016 0 -1046 l-6 -22 -649 0 -649 0
                                -2 413 c-2 228 1 418 6 425 7 10 119 12 524 12 328 -1 519 3 525 9 7 7 6 15
                                -3 26 -11 13 -76 15 -531 15 -285 0 -519 3 -521 8 -2 4 -2 46 0 95 l3 87 649
                                0 648 0 6 -22z"/>
                                <path d="M447 1504 c-12 -12 -8 -81 6 -92 23 -19 38 2 35 49 -3 42 -22 62 -41
                                43z"/>
                                <path d="M568 1470 c-4 -49 3 -63 28 -58 15 3 19 12 19 48 0 38 -3 45 -21 48
                                -19 3 -22 -3 -26 -38z"/>
                                <path d="M694 1497 c-11 -31 0 -82 20 -85 26 -5 34 12 27 56 -7 39 -36 57 -47
                                29z"/>
                                <path d="M826 1498 c-13 -40 -12 -59 1 -78 12 -17 18 -19 29 -9 22 18 15 93
                                -9 97 -9 2 -19 -2 -21 -10z"/>
                                <path d="M944 1496 c-3 -8 -4 -29 -2 -48 2 -28 7 -33 28 -33 27 0 37 26 26 71
                                -7 27 -43 34 -52 10z"/>
                                <path d="M1077 1504 c-12 -13 -7 -81 7 -93 21 -17 38 11 34 56 -3 36 -23 54
                                -41 37z"/>
                                <path d="M1200 1462 c0 -34 5 -52 14 -55 23 -9 38 18 34 60 -2 31 -7 39 -25
                                41 -21 3 -23 0 -23 -46z"/>
                                <path d="M1324 1496 c-3 -8 -4 -29 -2 -48 2 -25 8 -34 26 -36 20 -3 22 0 22
                                47 0 45 -3 51 -20 51 -11 0 -23 -6 -26 -14z"/>
                                <path d="M1457 1504 c-13 -14 -8 -92 7 -97 23 -9 38 18 34 60 -3 36 -23 54
                                -41 37z"/>
                                <path d="M1574 1497 c-3 -8 -4 -30 -2 -49 2 -28 7 -33 28 -33 23 0 25 4 25 45
                                0 39 -3 45 -23 48 -13 2 -24 -3 -28 -11z"/>
                                <path d="M1481 1351 c-8 -5 -11 -16 -8 -25 9 -23 145 -23 154 0 9 23 -15 34
                                -77 34 -30 0 -61 -4 -69 -9z"/>
                                <path d="M1081 1201 c-13 -8 -20 -31 -51 -166 -21 -93 -37 -155 -79 -320 -29
                                -109 -33 -165 -13 -165 28 1 44 43 77 200 7 36 29 126 49 200 47 182 56 218
                                56 234 0 17 -23 27 -39 17z"/>
                                <path d="M1233 1053 c-27 -10 -11 -39 52 -99 36 -34 65 -66 65 -70 0 -5 -30
                                -40 -65 -79 -63 -67 -76 -97 -45 -103 8 -1 55 38 105 88 l90 90 -80 81 c-85
                                86 -103 99 -122 92z"/>
                                <path d="M690 965 l-85 -85 90 -90 c50 -50 97 -89 105 -88 32 6 18 37 -49 108
                                l-69 75 69 65 c72 68 87 100 46 100 -15 0 -50 -28 -107 -85z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            Software Development
                        </h1>
                        <p className='md:text-base text-xs'>
                            Our Innovative Illustrators, Creative designers and highly skilled IT professionals are ready to transform your vision in reality and to facilitate your operations, while you are focused on your business.
                        </p>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md mt-5 md:mt-0'>
                    <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                                <path d="M0 995 l0 -995 1000 0 1000 0 0 995 0 995 -1000 0 -1000 0 0 -995z
                                m1928 3 l2 -918 -925 0 -925 0 0 920 0 920 923 -2 922 -3 3 -917z"/>
                                <path d="M479 1565 c-48 -26 -69 -77 -69 -163 0 -42 4 -83 8 -90 7 -10 121
                                -12 538 -10 521 3 529 3 529 23 0 20 -8 20 -505 25 -278 3 -506 6 -507 8 -11
                                9 0 117 13 137 l16 25 508 0 c478 0 508 -1 523 -18 15 -17 17 -67 17 -519 0
                                -470 -1 -501 -18 -516 -16 -15 -62 -17 -398 -19 l-379 -3 0 -25 0 -25 378 -3
                                c418 -3 419 -2 457 61 19 31 20 54 20 535 0 553 1 539 -60 576 -25 14 -83 16
                                -537 16 -421 -1 -512 -3 -534 -15z"/>
                                <path d="M534 1456 c-14 -36 13 -57 46 -36 12 7 12 13 4 27 -15 24 -43 28 -50
                                9z"/>
                                <path d="M630 1455 c-11 -13 -11 -19 3 -32 21 -21 49 -9 45 19 -4 28 -30 35
                                -48 13z"/>
                                <path d="M733 1463 c-18 -7 -16 -42 3 -50 8 -3 23 1 31 10 13 13 14 19 3 31
                                -13 17 -17 18 -37 9z"/>
                                <path d="M416 1215 c-3 -9 -6 -119 -6 -245 0 -189 3 -230 15 -240 8 -7 22 -10
                                30 -6 13 5 15 38 13 253 -3 244 -3 248 -24 251 -13 2 -24 -4 -28 -13z"/>
                                <path d="M1281 1169 c-36 -35 -41 -80 -15 -126 16 -28 16 -31 -22 -113 -73
                                -160 -76 -164 -122 -174 -40 -8 -42 -7 -111 50 -62 52 -70 62 -65 85 7 34 -18
                                94 -44 108 -12 6 -39 11 -62 11 -33 0 -48 -6 -72 -31 -29 -28 -30 -33 -25 -87
                                l6 -57 -93 -119 -92 -118 -48 -2 c-39 -2 -53 -7 -72 -29 -32 -37 -39 -71 -23
                                -108 14 -35 62 -69 97 -69 69 0 123 72 102 136 -11 33 -10 36 85 159 84 109
                                100 125 123 123 15 -1 37 2 49 6 19 6 36 -4 93 -50 66 -55 70 -60 70 -100 0
                                -51 27 -88 75 -104 89 -29 165 63 120 146 -17 33 -17 34 8 86 14 29 41 88 61
                                131 32 70 37 77 64 77 34 0 78 37 88 74 25 102 -100 170 -175 95z m115 -55
                                c14 -56 -65 -85 -85 -31 -12 32 8 57 46 57 27 0 34 -5 39 -26z m-524 -171 c25
                                -22 23 -50 -6 -68 -34 -23 -66 -5 -66 35 0 44 39 63 72 33z m313 -288 c0 -37
                                -25 -53 -59 -37 -33 15 -35 55 -4 76 19 13 25 14 43 2 12 -8 20 -24 20 -41z
                                m-630 -139 c22 -32 2 -66 -37 -66 -42 0 -62 45 -32 74 22 23 51 20 69 -8z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            I.T Consultant
                        </h1>
                        <p className='md:text-base text-xs'>
                            Our collective insight and best practices guarantee real business results every time you partner with us, ensuring a level of certainty that no other service provider can match.
                        </p>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-delay="300" data-aos-duration="400" className='md:py-10 py-4 px-5 max-w-md mt-5 md:mt-0'>
                    <svg className='first justify-center mx-auto text-center' version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="140.000000pt" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#CD252B" stroke="none">
                                <path d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
                                -1000z m1920 0 l0 -920 -920 0 -920 0 0 920 0 920 920 0 920 0 0 -920z"/>
                                <path d="M515 1542 c-51 -32 -54 -41 -57 -173 l-3 -124 -40 -5 c-53 -8 -104
                                -39 -113 -70 -4 -14 -7 -172 -7 -352 l0 -326 23 -25 c42 -45 62 -48 337 -45
                                l260 3 3 23 3 22 -269 0 c-259 0 -270 1 -288 21 -18 20 -18 21 6 40 39 30 348
                                247 369 259 11 5 25 16 33 23 7 7 34 27 59 44 l45 31 47 -27 c67 -39 73 -47
                                48 -60 -17 -9 -21 -21 -21 -59 0 -27 5 -53 11 -59 8 -8 8 -13 0 -17 -14 -9
                                -14 -99 0 -113 8 -8 8 -13 0 -17 -6 -4 -11 -31 -11 -60 0 -75 11 -79 237 -84
                                144 -3 194 -1 235 11 29 9 86 18 126 22 41 3 87 12 103 20 27 15 29 19 29 75
                                0 41 -4 60 -12 60 -10 0 -10 2 -1 8 9 5 11 94 10 342 l-2 335 -50 13 c-27 7
                                -107 13 -177 14 l-127 1 -133 133 -132 134 -256 0 c-230 0 -258 -2 -285 -18z
                                m485 -91 c0 -64 4 -83 21 -105 31 -40 57 -50 144 -56 l80 -6 -37 -16 c-37 -16
                                -38 -19 -38 -68 0 -36 4 -52 15 -57 14 -5 14 -8 -1 -24 -13 -15 -16 -30 -11
                                -74 l6 -55 -80 -51 c-43 -28 -79 -58 -79 -65 0 -21 10 -18 80 30 l65 45 3 -32
                                c2 -18 10 -37 17 -43 26 -20 -13 -41 -85 -46 -63 -5 -67 -4 -131 39 -36 23
                                -76 43 -88 43 -11 0 -51 -21 -88 -47 -97 -66 -182 -126 -207 -143 -178 -126
                                -223 -157 -233 -164 -10 -6 -13 55 -13 289 l0 295 80 -57 c44 -32 102 -74 129
                                -93 27 -19 59 -42 71 -50 12 -8 42 -30 66 -47 45 -35 64 -41 64 -20 0 6 -31
                                33 -68 59 -37 25 -102 70 -144 100 -48 33 -73 57 -67 62 5 6 9 90 9 189 0 103
                                4 186 10 197 24 44 53 49 288 50 l222 0 0 -79z m148 -22 c56 -56 102 -103 102
                                -105 0 -2 -33 -4 -74 -4 -60 0 -79 4 -107 23 -33 22 -34 25 -39 105 -3 54 -1
                                82 5 82 6 0 57 -46 113 -101z m491 -175 c3 -2 -1 -10 -8 -17 -17 -17 -312 -23
                                -370 -7 -97 26 10 41 239 33 74 -3 137 -7 139 -9z m-244 -61 c77 -3 165 0 195
                                5 61 12 82 3 64 -26 -16 -25 -65 -33 -219 -34 -170 -2 -225 10 -225 49 0 22 3
                                24 23 18 12 -4 85 -9 162 -12z m-937 -50 c2 -28 -1 -43 -8 -43 -15 0 -90 59
                                -90 70 0 11 28 18 65 17 28 -2 30 -5 33 -44z m1178 -36 c12 -12 -76 -22 -201
                                -22 -128 0 -210 9 -201 23 5 8 394 7 402 -1z m-231 -50 c61 -1 143 2 183 6 69
                                8 72 7 72 -13 0 -13 -10 -24 -27 -31 -44 -17 -268 -23 -350 -9 -70 11 -73 13
                                -73 40 0 28 0 28 43 18 23 -5 92 -10 152 -11z m245 -87 c0 -15 -49 -20 -215
                                -20 -163 0 -215 5 -215 19 0 4 97 7 215 7 118 1 215 -2 215 -6z m-220 -48 c63
                                0 141 3 173 7 55 7 57 6 57 -16 0 -12 -9 -27 -19 -33 -21 -11 -369 -14 -408
                                -4 -17 5 -23 14 -23 33 0 27 0 27 53 20 28 -4 104 -7 167 -7z m-125 -82 c15
                                -6 8 -9 -27 -9 -27 -1 -48 4 -48 9 0 12 47 12 75 0z m334 1 c21 -13 1 -20 -94
                                -30 -82 -10 -141 -4 -195 18 -8 3 41 8 110 11 69 3 134 7 145 8 11 1 26 -2 34
                                -7z m-255 -47 c125 -18 -111 -46 -271 -33 -115 10 -140 25 -57 34 33 3 68 8
                                79 10 26 5 186 -2 249 -11z m270 -31 c-12 -20 -37 -27 -121 -37 l-63 -7 0 31
                                c0 29 2 30 43 31 23 0 62 4 87 8 51 8 70 -1 54 -26z m-439 -28 c83 0 166 3
                                186 8 33 7 36 5 35 -15 -1 -32 -10 -36 -117 -49 -96 -11 -219 -6 -296 13 -36
                                9 -43 14 -43 34 0 23 2 24 43 17 23 -5 110 -8 192 -8z m423 -34 c19 -12 -14
                                -20 -113 -28 -73 -6 -79 -5 -67 10 9 10 34 17 70 20 31 2 66 4 77 5 11 1 26
                                -2 33 -7z m-433 -54 c55 0 128 3 163 8 39 4 62 3 62 -3 0 -18 -42 -22 -222
                                -22 -176 0 -208 3 -208 22 0 6 20 7 52 3 29 -4 98 -8 153 -8z m455 -7 c0 -26
                                -31 -38 -121 -46 l-69 -7 0 26 c0 24 4 26 58 32 31 3 64 8 72 9 43 10 60 6 60
                                -14z m-542 -43 c98 -10 260 -1 302 17 22 9 19 -43 -3 -56 -10 -5 -104 -8 -218
                                -6 -211 3 -218 5 -219 52 0 13 4 16 18 11 9 -4 63 -12 120 -18z m532 -36 c0
                                -9 -82 -21 -154 -21 -27 0 -46 3 -42 7 16 16 196 30 196 14z m-480 -48 c69 -3
                                151 -1 182 5 31 5 62 5 69 1 21 -12 -78 -24 -206 -24 -133 0 -219 11 -209 26
                                3 6 13 7 23 3 9 -3 72 -9 141 -11z m483 -34 c-4 -4 -48 -12 -96 -17 -85 -8
                                -87 -8 -87 14 0 20 7 23 73 29 39 4 81 11 92 15 15 6 21 3 23 -12 2 -11 0 -24
                                -5 -29z m-508 -14 c76 -6 241 6 277 20 14 5 16 1 14 -22 -3 -26 -8 -29 -62
                                -41 -59 -13 -296 -10 -357 4 -30 7 -43 25 -34 48 4 11 15 12 44 6 21 -5 74
                                -11 118 -15z"/>
                                <path d="M812 1387 c-19 -24 -109 -182 -116 -204 -12 -39 18 -27 43 17 27 48
                                39 51 43 8 6 -68 108 -57 108 12 0 36 -29 54 -76 47 -19 -3 -34 -3 -34 0 0 3
                                14 28 30 55 17 26 30 52 30 58 0 15 -17 20 -28 7z m36 -184 c-14 -14 -20 -14
                                -33 -3 -14 12 -14 15 2 32 15 17 18 17 32 3 14 -13 14 -17 -1 -32z"/>
                                <path d="M657 1372 c-46 -51 13 -124 69 -87 31 20 34 79 5 95 -29 15 -56 12
                                -74 -8z m60 -28 c9 -23 -13 -40 -32 -24 -12 10 -13 16 -4 26 15 18 28 18 36
                                -2z"/>
                            </g>
                        </svg>
                        <h1 className='text-lg font-semibold justify-center py-2 md:py-5'>
                            Digital Assets
                        </h1>
                        <p className='md:text-base text-xs'>
                            Digital currency can be programmed to move value between parties under predetermined conditions. These payments can be auto-enabled and controlled, based on supply chain…
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;